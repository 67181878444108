import styled from 'styled-components';
import { mobile } from '../responsive';
import { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";

import "../assets/css/login.css";
import bgImg from '../assets/images/fst-wall-paper.png';
import Logo from '../assets/images/logo-white.png';
import {TrackingNumbers, Packages} from '../data';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0px;
    margin: 0px;
`;
const BtnText = styled.span`
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    display: ${props=> props.IsTrue === false? "none" : "block"};
`;
const BtnSpinner = styled.div`
    width: 100%;
    height: 100%;
    display: ${props=> props.IsTrue === false? "none" : "block"};
`;

export default function Login() {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        trackingId: "",
        errorMessage: ""
    });
    const [btnText, setBtnText] = useState(true);
    const [btnSpinner, setBtnSpinner] = useState(false);

    useEffect(() => {
        document.title = "Login - FST Courier";
        window.scrollTo(0, 0)
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        let _trackingId = e.target.trackingId.value;
        let regex = /^[a-zA-Z0-9]{10}$/;
        let formatIsValid = regex.test(_trackingId);
        let _url = "";

        function turnOnSpinners() {
            setBtnText(false);
            setBtnSpinner(true);
        }
        function turnOffSpinners() {
            setBtnText(true);
            setBtnSpinner(false);
            navigate(_url); 
        }
        function myTimeout() {
            setTimeout(
                () => turnOffSpinners(), 
                3000
            );
        }

        if(formatIsValid){
            if (TrackingNumbers[_trackingId]) {
                let _index = Number(TrackingNumbers[_trackingId].pass);
                let _package = Packages[_index];
                if(_package.status == "IN TRANSIT") {
                    _url = "/tracking_t/" + _trackingId;
                    turnOnSpinners();
                    myTimeout(); 
                }
                if(_package.status == "PICKED UP") {
                    _url = "/tracking_p/" + _trackingId;
                    turnOnSpinners();
                    myTimeout(); 
                 }
            }else {
                setValues({ ...values, errorMessage: "This ID is NOT valid"});
            }
        }else {
            setValues({ ...values, errorMessage: "This ID is NOT valid."});
        }     
    };

    return (
        <Container>
            <div className="login-container">
                <div className="container-inner">
                    <div className="header" style={{ backgroundImage: `url(${bgImg})`}} >
                        <img src={Logo} />
                    </div>
                    <div className="form-wrap">
                        <h1>Enter your tracking ID</h1>
                        <p className="error-message">{values.errorMessage}</p>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text" 
                                name="trackingId"
                                placeholder="Tracking ID"
                                required
                            />
                            <button >
                                <BtnText IsTrue={btnText}>
                                    Track Your Shipment
                                </BtnText>
                                <BtnSpinner IsTrue={btnSpinner}>
                                    <div class="lds-dual-ring"></div>
                                </BtnSpinner>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
}
